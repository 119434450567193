import React from 'react'

export default ({ sidebar, children }) =>
  sidebar ? (
    <div className="page">
      <sidebar.Sidebar activeIndex={sidebar.activeIndex} />

      <div className="content">{children}</div>
    </div>
  ) : (
    <div className="page content">{children}</div>
  )
