// @flow
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchProtectedData } from '../actions'
import AbstractPage from './AbstractPage'
import Loader from 'react-loader'

export class ProtectedPage extends React.Component {
  componentWillMount() {
    this.fetchData()
  }

  fetchData() {
    let token = this.props.auth.token
    this.props.actions.fetchProtectedData(token)
  }

  render() {
    return (
      <AbstractPage>
        <Loader loaded={!this.props.isFetching}>
          {
            <div>
              <h1>Welcome back, {this.props.auth.userName}!</h1>
              <h3>Async data: {JSON.stringify(this.props.data)}</h3>
              <p>Your token is {this.props.auth.token}</p>
            </div>
          }
        </Loader>
      </AbstractPage>
    )
  }
}

const mapStateToProps = state => ({
  data: state.data.data,
  auth: {
    username: state.auth.username,
    token: state.auth.token
  },
  isFetching: state.data.isFetching
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchProtectedData }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedPage)
