import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { createLogger } from 'redux-logger'

export default function configureStore(baseHistory, initialState) {
  const routingMiddleware = routerMiddleware(baseHistory)
  const logger = createLogger()
  const middleware = applyMiddleware(routingMiddleware, thunk, logger)
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(rootReducer, initialState, composeEnhancers(middleware))

  return store
}
