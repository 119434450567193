// @flow

import { combineReducers } from '../utils'

const initialState = {
  users: undefined,
  isFetching: false,
  isResettingPass: false,
  resetPassText: undefined,
  isDeletingUser: false,
  deleteUserText: undefined,
  isChangingPass: false,
  changePassText: undefined,
  isSettingGroup: false,
  setGroupText: undefined
}

export default combineReducers(initialState, {
  RECEIVE_USERS: (state, payload) => ({
    ...state,
    users: [...payload.users],
    isFetching: false
  }),
  FETCH_USERS_REQUEST: state => ({
    ...state,
    isFetching: true
  }),
  RESET_PASSWORD_REQUEST: state => ({
    ...state,
    isResettingPass: true,
    resetPassText: undefined
  }),
  RESET_PASSWORD_RESULT: (state, payload) => ({
    ...state,
    isResettingPass: false,
    resetPassText: payload.message
  }),
  CHANGE_PASSWORD_REQUEST: state => ({
    ...state,
    isChangingPass: true,
    changePassText: undefined
  }),
  CHANGE_PASSWORD_RESULT: (state, payload) => ({
    ...state,
    isChangingPass: false,
    changePassText: payload.message
  }),
  DELETE_USER_REQUEST: (state, payload) => ({
    ...state,
    isDeletingUser: true,
    deleteUserText: undefined
  }),
  DELETE_USER_RESULT: (state, payload) => ({
    ...state,
    isDeletingUser: false,
    deleteUserText: payload.message
  }),
  SET_GROUP_REQUEST: (state, payload) => ({
    ...state,
    isSettingGroup: true,
    setGroupText: undefined
  }),
  SET_GROUP_RESULT: (state, payload) => ({
    ...state,
    isSettingGroup: false,
    setGroupText: payload.message
  })
})
