import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutAndRedirect } from '../actions'
import { Navbar, Nav, NavItem } from 'react-bootstrap'
import { rif } from '../utils'
import { LinkContainer } from 'react-router-bootstrap'
import shortid from 'shortid'

class CoreLayout extends React.Component {
  render() {
    return (
      <div className="core-layout">
        <Navbar fixedTop>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Time Card Audit</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {rif(this.props.isAuthenticated && this.props.isAnyAdmin, [
                <LinkContainer key={shortid.generate()} to="/admin">
                  <NavItem>Admin</NavItem>
                </LinkContainer>
              ])}
              {rif(
                this.props.isAuthenticated,
                [
                  <LinkContainer key={shortid.generate()} to="/changePassword">
                    <NavItem>Change Password</NavItem>
                  </LinkContainer>,
                  <NavItem
                    key={shortid.generate()}
                    onClick={() => this.props.dispatch(logoutAndRedirect())}
                  >
                    Logout
                  </NavItem>
                ],
                [
                  <LinkContainer key={shortid.generate()} to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>,
                  <LinkContainer key={shortid.generate()} to="/signup">
                    <NavItem>Enroll</NavItem>
                  </LinkContainer>
                ]
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAnyAdmin: state.auth.isAdmin || state.auth.isGroupAdmin,
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps)(CoreLayout)
