import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signupUser, signupFailure } from '../actions'
import { FieldGroup, rif } from '../utils'
import { Button } from 'react-bootstrap'
import AbstractPage from './AbstractPage'

class SignupPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      passwordCheck: '',
      passwordCheckHelp: ''
    }
  }

  signup(e) {
    e.preventDefault()
    if (this.state.password !== this.state.passwordCheck) {
      this.setState({ ...this.state, passwordCheckHelp: 'Passwords do not match' })
      return
    }

    this.setState({ ...this.state, passwordCheckHelp: '' })
    this.props.actions.signupUser(this.state.email, this.state.password)
  }

  render() {
    return (
      <AbstractPage>
        <div className="col-xs-12 col-md-6 col-md-offset-3">
          {rif(
            this.props.statusText,
            <div className="alert alert-danger">{this.props.statusText}</div>
          )}
          <h2>New User Enrollment</h2>
          <form>
            <FieldGroup
              type="email"
              label="Email address"
              placeholder="Enter email"
              onChange={event => this.setState({ ...this.state, email: event.target.value })}
            />
            <FieldGroup
              type="password"
              label="Password"
              placeholder="Enter password"
              onChange={event => this.setState({ ...this.state, password: event.target.value })}
            />
            <FieldGroup
              type="password"
              label="Password"
              placeholder="Enter password again"
              help={this.state.passwordCheckHelp}
              onChange={event =>
                this.setState({ ...this.state, passwordCheck: event.target.value })
              }
            />
            <Button
              className="pull-right"
              bsStyle="primary"
              type="submit"
              onClick={this.signup.bind(this)}
            >
              Submit
            </Button>
          </form>
        </div>
      </AbstractPage>
    )
  }
}

const mapStateToProps = state => ({
  statusText: state.auth.signupText
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ signupUser, signupFailure }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupPage)
