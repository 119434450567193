import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

class AuthProtect extends React.Component {
  componentWillMount() {
    if (!this.props.isAuthenticated || (this.props.requireAdmin && !this.props.isAnyAdmin))
      this.props.dispatch(push('/login?redirect=' + this.props.location.pathname))
  }

  render() {
    const { isAuthenticated } = this.props
    return <>{isAuthenticated ? this.props.children : ''}</>
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAnyAdmin: state.auth.isAdmin || state.auth.isGroupAdmin
  }
}

export default connect(mapStateToProps)(AuthProtect)
