import { push } from 'react-router-redux'
import { makeAbsoluteUrl } from '../utils'

export const loginUserSuccess = token => {
  localStorage.setItem('token', token)
  return {
    type: 'LOGIN_USER_SUCCESS',
    payload: {
      token: token
    }
  }
}

export const loginUserFailure = (status, statusText) => {
  localStorage.removeItem('token')
  return {
    type: 'LOGIN_USER_FAILURE',
    payload: {
      status,
      statusText
    }
  }
}

export const signupFailure = (status, statusText) => ({
  type: 'SIGNUP_FAILURE',
  payload: {
    status,
    statusText
  }
})

export const signupUser = (email, password) => dispatch => {
  const postBody = JSON.stringify({ email, password })
  fetch(makeAbsoluteUrl('/api/signup'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: postBody
  })
    .then(response => response.json())
    .then(response => {
      if (response.result === true) {
        dispatch(loginUserSuccess(response.token))
        dispatch(push('/'))
      } else {
        dispatch(signupFailure(403, response.error))
      }
    })
    .catch(err => {
      dispatch(signupFailure(500, err))
    })
}

export const logout = () => {
  localStorage.removeItem('token')
  return {
    type: 'LOGOUT_USER'
  }
}

export const loginUserRequest = () => ({ type: 'LOGIN_USER_REQUEST' })

export const loginUser = (email, password, redirect = '/') => {
  return function(dispatch) {
    dispatch(loginUserRequest())
    const postBody = JSON.stringify({ email, password })
    fetch(makeAbsoluteUrl('/api/login'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: postBody
    })
      .then(response => response.json())
      .then(response => {
        if (response.result === true) {
          dispatch(loginUserSuccess(response.token))
          dispatch(push(redirect))
        } else {
          dispatch(loginUserFailure(403, 'failed authentication'))
        }
      })
      .catch(err => {
        dispatch(loginUserFailure(500, err))
      })
  }
}

export const logoutAndRedirect = () => {
  return (dispatch, state) => {
    dispatch(logout())
    window.location.replace('/login')
  }
}

export const receiveProtectedData = data => ({
  type: 'RECEIVE_PROTECTED_DATA',
  payload: {
    data: data
  }
})

export const fetchProtectedDataRequest = () => ({
  type: 'FETCH_PROTECTED_DATA_REQUEST'
})

export const fetchUsers = token => (dispatch, state) => {
  dispatch({ type: 'FETCH_USERS_REQUEST' })
  fetch(makeAbsoluteUrl('/api/protected/users'), {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(response => response.json())
    .then(response => {
      if (response.result) {
        dispatch({
          type: 'RECEIVE_USERS',
          payload: {
            users: response.result
          }
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export const setUserAccessList = (token, email, accessList) => (dispatch, state) => {
  const postBody = JSON.stringify({ email, accessList })
  fetch(makeAbsoluteUrl('/api/protected/setUserAccessList'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: postBody
  })
    .then(response => response.json())
    .then(response => {
      return fetchUsers(token)(dispatch, state)
    })
    .catch(err => {
      console.log(err)
    })
}

export const setUserGroup = (token, email, group) => (dispatch, state) => {
  const body = JSON.stringify({ email, group })
  dispatch({ type: 'SET_GROUP_REQUEST' })
  fetch(makeAbsoluteUrl('/api/protected/setUserGroup'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body
  })
    .then(response => response.json())
    .then(response => {
      if (response.result) {
        dispatch({
          type: 'SET_GROUP_RESULT',
          payload: {
            result: response.result,
            message: 'Set group successful'
          }
        })
      } else {
        dispatch({
          type: 'SET_GROUP_RESULT',
          payload: {
            result: response.result,
            message: response.error
          }
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export const resetUserPassword = (token, email, password) => (dispatch, state) => {
  const body = JSON.stringify({ email, password })
  dispatch({ type: 'RESET_PASSWORD_REQUEST' })
  fetch(makeAbsoluteUrl('/api/protected/resetUserPassword'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body
  })
    .then(response => response.json())
    .then(response => {
      if (response.result) {
        dispatch({
          type: 'RESET_PASSWORD_RESULT',
          payload: {
            result: response.result,
            message: 'Reset password successful'
          }
        })
      } else {
        dispatch({
          type: 'RESET_PASSWORD_RESULT',
          payload: {
            result: response.result,
            message: response.error
          }
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export const deleteUser = (token, email) => (dispatch, state) => {
  const body = JSON.stringify({ email })
  dispatch({ type: 'DELETE_USER_REQUEST' })
  fetch(makeAbsoluteUrl('/api/protected/deleteUser'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body
  })
    .then(response => response.json())
    .then(response => {
      if (response.result) {
        dispatch({
          type: 'DELETE_USER_RESULT',
          payload: {
            result: response.result,
            message: 'Delete user successful'
          }
        })
      } else {
        dispatch({
          type: 'DELETE_USER_RESULT',
          payload: {
            result: response.result,
            message: response.error
          }
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export const changeMyPassword = (token, password) => (dispatch, state) => {
  const body = JSON.stringify({ password })
  dispatch({ type: 'CHANGE_PASSWORD_REQUEST' })
  fetch(makeAbsoluteUrl('/api/protected/changeMyPassword'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body
  })
    .then(response => response.json())
    .then(response => {
      if (response.result) {
        dispatch({
          type: 'CHANGE_PASSWORD_RESULT',
          payload: {
            result: response.result,
            message: 'Change password successful'
          }
        })
      } else {
        dispatch({
          type: 'CHANGE_PASSWORD_RESULT',
          payload: {
            result: response.result,
            message: response.error
          }
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export const getTime = (token, startDate, endDate) => (dispatch, state) => {
  dispatch({ type: 'FETCH_TIME_DATA_REQUEST' })
  const postBody = JSON.stringify({ startDate, endDate })
  fetch(makeAbsoluteUrl('/api/protected/getTime'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: postBody
  })
    .then(response => response.json())
    .then(response => {
      if (response.result) {
        dispatch({
          type: 'RECEIVE_TIME_DATA',
          payload: { timeData: response.result }
        })
      } else {
        dispatch({
          type: 'FETCH_TIME_DATA_ERROR',
          payload: response.error
        })
      }
    })
    .catch(err => {
      dispatch({
        type: 'FETCH_TIME_DATA_ERROR',
        payload: err
      })
    })
}

export const fetchProtectedData = token => {
  return (dispatch, state) => {
    dispatch(fetchProtectedDataRequest())
    setTimeout(() => dispatch(receiveProtectedData({ protectedThing: 'secret' })), 500)
  }
}
