// @flow

import React from 'react'
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'
import urlJoin from 'url-join'
import settings from '../config/settings'
import moment from 'moment'

export const rif = (conditional, ifTrue, ifFalse) => (conditional ? ifTrue || '' : ifFalse || '')

export const FieldGroup = ({ id, label, help, ...props }) => (
  <FormGroup controlId={id}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl {...props} />
    {help && <HelpBlock className="alert alert-danger">{help}</HelpBlock>}
  </FormGroup>
)

export function combineReducers(initialState, reducerMap) {
  return (state = initialState, action) =>
    reducerMap.hasOwnProperty(action.type) ? reducerMap[action.type](state, action.payload) : state
}

export const makeAbsoluteUrl = relativeUrl => urlJoin(settings.baseUrl, relativeUrl)

const endOfPeriod = date => {
  const momentDate = moment(date)

  return momentDate.isoWeekday(7).set({ h: 23, m: 59, s: 59, ms: 999 })
}

const getPeriodEndDatesForYear = year => {
  const res = []
  const workingDate = moment(new Date(year, 0, 1))
    .utc()
    .set({ h: 11, m: 59, s: 59, ms: 999 })
  let isWeek1 = true
  while (true) {
    const periodEnd = endOfPeriod(workingDate)
    const periodStart = moment(periodEnd)
      .subtract(6, 'days')
      .set({ h: 0, m: 0, s: 0, ms: 0 })
    if (periodEnd.year() > year) break
    res.push({
      periodStart: periodStart.format('YYYY-MM-DD'),
      periodEnd: periodEnd.format('YYYY-MM-DD'),
      label: `${periodEnd.format('MM/DD/YY')} [Week ${isWeek1 ? 1 : 2}]`
    })

    workingDate.add(1, 'week')
    isWeek1 = !isWeek1
  }

  return res
}

export const getRecentPayrollPeriods = (date = moment()) => {
  const momentDate = moment(date)
  return [
    ...getPeriodEndDatesForYear(momentDate.year()),
    ...getPeriodEndDatesForYear(momentDate.year() - 1)
  ]
    .filter(e => moment(e.periodEnd).isBefore(moment(momentDate).add(2, 'weeks')))
    .sort((a, b) => moment(b.periodEnd) - moment(a.periodEnd))
    .slice(0, 14)
}
