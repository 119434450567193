import React from 'react'
import { connect } from 'react-redux'
import AdminSidebar from '../../containers/Sidebar/AdminSidebar'
import AbstractPage from '../AbstractPage'
import {
  fetchUsers,
  setUserAccessList,
  resetUserPassword,
  deleteUser,
  setUserGroup
} from '../../actions'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import UserGrid from '../../containers/UserGrid'
import classNames from 'classnames'
import { Button, Modal, Alert } from 'react-bootstrap'
import { FieldGroup } from '../../utils'

import './Admin.css'
import './ManageUser.css'

class ManageUser extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cannotAccessSelected: [],
      canAccessSelected: [],
      resetPasswordShown: false,
      deleteUserModalShown: false,
      setGroupModalShown: false,
      newPassword: '',
      newGroup: '',
      submittedResetForm: false,
      submittedDeleteForm: false,
      submittedSetGroupForm: false
    }

    this.resetPasswordClicked = this.resetPasswordClicked.bind(this)
    this.deleteUserClicked = this.deleteUserClicked.bind(this)
    this.setGroupClicked = this.setGroupClicked.bind(this)
    this.closeResetPassword = this.closeResetPassword.bind(this)
    this.closeDeleteUserModal = this.closeDeleteUserModal.bind(this)
    this.closeSetGroupModal = this.closeSetGroupModal.bind(this)
  }

  componentWillMount() {
    if (!this.props.users) {
      this.fetchUsers()
      return
    }
  }

  fetchUsers() {
    this.props.actions.fetchUsers(this.props.auth.token)
  }

  renderContent = (users, currentUser) => {
    const cannotAccess = users
      .filter(e => e.email !== currentUser.email)
      .filter(e => !currentUser.accessUsers || !currentUser.accessUsers.includes(e.email))

    const canAccess = users
      .filter(e => e.email !== currentUser.email)
      .filter(e => !cannotAccess.map(ca => ca.email).includes(e.email))

    const rightClasses = classNames('btn', 'btn-primary', {
      disabled: this.state.cannotAccessSelected.length === 0
    })
    const doubleRightClasses = classNames('btn', 'btn-primary')
    const leftClasses = classNames('btn', 'btn-primary', {
      disabled: this.state.canAccessSelected.length === 0
    })
    const doubleLeftClasses = classNames('btn', 'btn-primary')

    const onCannotAccessSelect = user => {
      const newCannotAccessSelected = !this.state.cannotAccessSelected.includes(user.email)
        ? [...this.state.cannotAccessSelected, user.email]
        : this.state.cannotAccessSelected.filter(e => e !== user.email)
      this.setState({
        ...this.state,
        cannotAccessSelected: newCannotAccessSelected
      })
    }

    const onCanAccessSelect = (user, isSelected) => {
      const newCanAccessSelected = !this.state.cannotAccessSelected.includes(user.email)
        ? [...this.state.canAccessSelected, user.email]
        : this.state.canAccessSelected.filter(e => e !== user.email)
      this.setState({
        ...this.state,
        canAccessSelected: newCanAccessSelected
      })
    }

    const clearSelection = () => {
      this.setState({
        cannotAccessSelected: [],
        canAccessSelected: []
      })
    }

    const doubleRightClicked = () => {
      const newCanAccess = [...cannotAccess, ...canAccess].map(e => e.email)
      this.props.actions.setUserAccessList(this.props.auth.token, this.getEmail(), newCanAccess)
      clearSelection()
    }

    const doubleLeftClicked = () => {
      const newCanAccess = []
      this.props.actions.setUserAccessList(this.props.auth.token, this.getEmail(), newCanAccess)
      clearSelection()
    }

    const rightClicked = () => {
      const newCanAccess = [...canAccess.map(e => e.email), ...this.state.cannotAccessSelected]
      this.props.actions.setUserAccessList(this.props.auth.token, this.getEmail(), newCanAccess)
      clearSelection()
    }

    const leftClicked = () => {
      const newCanAccess = canAccess
        .map(e => e.email)
        .filter(e => !this.state.canAccessSelected.includes(e))
      this.props.actions.setUserAccessList(this.props.auth.token, this.getEmail(), newCanAccess)
      clearSelection()
    }

    return (
      <div>
        <Button onClick={this.resetPasswordClicked}>Reset Password</Button>
        <Button style={{ marginLeft: '20px' }} onClick={this.deleteUserClicked}>
          Delete User
        </Button>
        <Button style={{ marginLeft: '20px' }} onClick={this.setGroupClicked}>
          Set Group
        </Button>
        <Modal
          aria-labelledby="modal-label"
          show={this.state.setGroupModalShown}
          onHide={this.closeSetGroupModal}
        >
          <form>
            <FieldGroup
              type="text"
              label="New group"
              placeholder="Enter group"
              onChange={event => this.setState({ ...this.state, newGroup: event.target.value })}
            />
            <Button
              className="pull-right"
              bsStyle="primary"
              type="submit"
              onClick={this.setGroup.bind(this)}
            >
              Submit
            </Button>
          </form>
          {this.state.submittedSetGroupForm && !this.props.isSettingGroup
            ? this.props.setGroupText
            : ''}
        </Modal>
        <Modal
          aria-labelledby="modal-label"
          show={this.state.resetPasswordShown}
          onHide={this.closeResetPassword}
        >
          <form>
            <FieldGroup
              type="text"
              label="New Password"
              placeholder="Enter password"
              onChange={event => this.setState({ ...this.state, newPassword: event.target.value })}
            />
            <Button
              className="pull-right"
              bsStyle="primary"
              type="submit"
              onClick={this.resetPassword.bind(this)}
            >
              Submit
            </Button>
          </form>
          {this.state.submittedResetForm && !this.props.isResettingPass
            ? this.props.resetPassText
            : ''}
        </Modal>
        <Modal
          aria-labelledby="modal-label"
          show={this.state.deleteUserModalShown}
          onHide={this.closeDeleteUserModal}
        >
          <form>
            <p>Are you sure you want to delete {this.getEmail.call(this)}?</p>
            {this.state.submittedDeleteForm && !this.props.isDeletingUser ? (
              <Alert variant="info">{this.props.deleteUserText}</Alert>
            ) : (
              ''
            )}
            <Button
              style={{ marginLeft: '20px' }}
              className="pull-right"
              bsStyle="danger"
              type="submit"
              onClick={this.deleteUser.bind(this)}
            >
              Yes
            </Button>
            <Button
              className="pull-right"
              bsStyle="default"
              type="submit"
              onClick={e => {
                e.preventDefault()
                this.closeDeleteUserModal()
              }}
            >
              No
            </Button>
          </form>
        </Modal>

        <div className="user-selector">
          <div className="selector">
            <UserGrid
              onSelect={onCannotAccessSelect}
              users={cannotAccess}
              selectedUsers={this.state.cannotAccessSelected}
              title="Can't Access"
            />
          </div>
          <div className="controls">
            <button onClick={doubleRightClicked} className={doubleRightClasses}>
              <span className="fa fa-angle-double-right" />
            </button>
            <button onClick={rightClicked} className={rightClasses}>
              <span className="fa fa-angle-right" />
            </button>
            <button onClick={leftClicked} className={leftClasses}>
              <span className="fa fa-angle-left" />
            </button>
            <button onClick={doubleLeftClicked} className={doubleLeftClasses}>
              <span className="fa fa-angle-double-left" />
            </button>
          </div>
          <div className="selector">
            <UserGrid
              onSelect={onCanAccessSelect}
              users={canAccess}
              selectedUsers={this.state.canAccessSelected}
              title="Can Access"
            />
          </div>
        </div>
      </div>
    )
  }

  getEmail() {
    return decodeURIComponent(this.props.match.params.email)
  }

  resetPassword(e) {
    e.preventDefault()
    this.setState({ ...this.state, submittedResetForm: true })
    this.props.actions.resetUserPassword(
      this.props.auth.token,
      this.getEmail(),
      this.state.newPassword
    )
  }

  setGroup(e) {
    e.preventDefault()
    this.setState({ ...this.state, submittedSetGroupForm: true })
    this.props.actions.setUserGroup(this.props.auth.token, this.getEmail(), this.state.newGroup)
  }

  deleteUser(e) {
    e.preventDefault()
    this.setState({ ...this.state, submittedDeleteForm: true })
    this.props.actions.deleteUser(this.props.auth.token, this.getEmail())
  }

  resetPasswordClicked() {
    this.setState({ ...this.state, resetPasswordShown: true, submittedResetForm: false })
  }

  deleteUserClicked() {
    this.setState({ ...this.state, deleteUserModalShown: true, submittedDeleteForm: false })
  }

  setGroupClicked() {
    this.setState({ ...this.state, setGroupModalShown: true, submittedSetGroupForm: false })
  }

  closeDeleteUserModal() {
    this.setState({ ...this.state, deleteUserModalShown: false })
  }

  closeResetPassword() {
    this.setState({ ...this.state, resetPasswordShown: false })
  }

  closeSetGroupModal() {
    this.setState({ ...this.state, setGroupModalShown: false })
  }

  render() {
    const email = this.getEmail()
    const user = (this.props.users || []).find(e => e.email === email)

    console.log(this.props.auth.group)

    const filteredUsers = this.props.auth.isGroupAdmin
      ? (this.props.users || []).filter(e => e.group === this.props.auth.group)
      : this.props.users || []

    return (
      <AbstractPage sidebar={{ Sidebar: AdminSidebar, activeIndex: 0 }}>
        <Loader loaded={!this.props.isFetching}>
          {!user ? (
            <span>Cannot find specified user</span>
          ) : (
            this.renderContent(filteredUsers, user)
          )}
        </Loader>
      </AbstractPage>
    )
  }
}

const mapStateToProps = state => ({
  isSettingGroup: state.users.isSettingGroup,
  setGroupText: state.users.setGroupText,
  isResettingPass: state.users.isResettingPass,
  resetPassText: state.users.resetPassText,
  isDeletingUser: state.users.isDeletingUser,
  deleteUserText: state.users.deleteUserText,
  isFetching: state.users.isFetching,
  users: state.users.users,
  auth: {
    token: state.auth.token,
    isGroupAdmin: state.auth.isGroupAdmin,
    group: state.auth.group
  }
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { fetchUsers, setUserAccessList, resetUserPassword, deleteUser, setUserGroup },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUser)
