import React from 'react'
import { connect } from 'react-redux'
import { UserSidebar } from '../containers'
import AbstractPage from './AbstractPage'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../logo.png'

class HomePage extends React.Component {
  render() {
    return (
      <AbstractPage sidebar={{ Sidebar: UserSidebar }}>
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'flex-direction': 'column'
          }}
        >
          <img src={logo} alt="logo" width="400px" />
          <div style={{ 'margin-top': '50px' }}>
            <Link to="/login">
              <Button
                bsStyle="primary btn-lg"
                type="button"
                style={{ backgroundColor: '#0D3777', 'margin-right': '50px' }}
              >
                Login
              </Button>
            </Link>
            <Link to="/signup">
              <Button bsStyle="primary btn-lg" type="button" style={{ backgroundColor: '#0D3777' }}>
                New User Enrollment
              </Button>
            </Link>
          </div>
        </div>
      </AbstractPage>
    )
  }
}

export default connect()(HomePage)
