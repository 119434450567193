// @flow

import { combineReducers } from '../utils'

const initialState = {
  data: {},
  isFetching: false
}

export default combineReducers(initialState, {
  RECEIVE_PROTECTED_DATA: (state, payload) => ({
    ...state,
    data: payload.data,
    isFetching: false
  }),
  FETCH_PROTECTED_DATA_REQUEST: state => ({
    ...state,
    isFetching: true
  })
})
