import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import PropTypes from 'prop-types'
import { CoreLayout, AuthProtect } from '../containers'
import {
  HomePage,
  LoginPage,
  AdminPage,
  SignupPage,
  ManageUsersPage,
  ManageUserPage,
  TimeReportPage,
  ChangePasswordPage
} from '../pages'

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={this.props.history}>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <CoreLayout>
                  <HomePage {...props} />
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/login"
              render={props => (
                <CoreLayout>
                  <LoginPage {...props} />
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/signup"
              render={props => (
                <CoreLayout>
                  <SignupPage {...props} />
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/changePassword"
              render={props => (
                <CoreLayout>
                  <ChangePasswordPage {...props} />
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/timeReport"
              render={props => (
                <CoreLayout>
                  <AuthProtect {...props}>
                    <TimeReportPage {...props} />
                  </AuthProtect>
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/admin"
              render={props => (
                <CoreLayout>
                  <AuthProtect requireAdmin="true" {...props}>
                    <AdminPage {...props} />
                  </AuthProtect>
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/admin/manageUsers"
              render={props => (
                <CoreLayout>
                  <AuthProtect requireAdmin="true" {...props}>
                    <ManageUsersPage {...props} />
                  </AuthProtect>
                </CoreLayout>
              )}
            />
            <Route
              exact
              path="/admin/manageUsers/:email"
              render={props => (
                <CoreLayout>
                  <AuthProtect requireAdmin="true" {...props}>
                    <ManageUserPage {...props} />
                  </AuthProtect>
                </CoreLayout>
              )}
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    )
  }
}
