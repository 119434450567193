import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import qs from 'query-string'
import { loginUser } from '../actions'
import { Button } from 'react-bootstrap'
import { FieldGroup, rif } from '../utils'
import AbstractPage from './AbstractPage'

export class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    // if user tried to navigate to an auth required page, but wasn't logged in,
    // make sure we redirect to the right page. Otherwise, redirect to home view.
    const query = qs.parse(this.props.location.search)
    const redirectRoute = query.redirect || '/'
    this.state = {
      email: '',
      password: '',
      redirectTo: redirectRoute
    }
  }

  login(e) {
    e.preventDefault()
    this.props.actions.loginUser(this.state.email, this.state.password, this.state.redirectTo)
  }

  render() {
    return (
      <AbstractPage>
        <div className="col-xs-12 col-md-6 col-md-offset-3">
          {rif(
            this.props.statusText,
            <div className="alert alert-danger">{this.props.statusText}</div>
          )}
          <h2>Login</h2>
          <form>
            <FieldGroup
              type="email"
              label="Email address"
              placeholder="Enter email"
              onChange={event => this.setState({ ...this.state, email: event.target.value })}
            />
            <FieldGroup
              label="Password"
              type="password"
              placeholder="Enter password"
              onChange={event => this.setState({ ...this.state, password: event.target.value })}
            />
            <Button
              className="pull-right"
              bsStyle="primary"
              type="submit"
              onClick={this.login.bind(this)}
            >
              Submit
            </Button>
          </form>
        </div>
      </AbstractPage>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.loginText
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loginUser }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)
