import React from 'react'
import { connect } from 'react-redux'
import { UserSidebar } from '../containers'
import AbstractPage from './AbstractPage'
import { getTime } from '../actions'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import { Table } from 'react-bootstrap'
import { rif } from '../utils'
import { Button, ControlLabel } from 'react-bootstrap'
import moment from 'moment-timezone'
import Select from 'react-select'
import { getRecentPayrollPeriods } from '../utils/index'

const renderTable = timeData => {
  const processedTimeData = timeData.map(e => ({
    ...e,
    departSIS: moment(e.departSIS).toDate(),
    arrivePlant: moment(e.arrivePlant).toDate(),
    departPlant: moment(e.departPlant).toDate(),
    arriveSIS: moment(e.arriveSIS).toDate(),
    type: 'item'
  }))
  const userList = [...new Set(processedTimeData.map(e => e.contact))]
  const summaries = userList.map(contact => {
    const subtotal = processedTimeData
      .filter(f => contact === f.contact)
      .reduce((a, b) => a + b.totalHours, 0)
    return { contact, totalHours: subtotal, type: 'summary' }
  })

  const timeDataWithSummaries = [...processedTimeData, ...summaries].sort((a, b) => {
    if (a.contact.toLowerCase() < b.contact.toLowerCase()) return -1
    if (a.contact.toLowerCase() > b.contact.toLowerCase()) return 1
    if (a.type === 'item' && b.type === 'summary') return -1
    if (a.type === 'summary') return 1
    if (a.departSIS < b.departSIS) return -1
    if (a.departSIS > b.departSIS) return 1

    return 0
  })

  return (
    <div>
      <div>
        <b>** Report does not include lunch deduction</b>
      </div>
      <div>
        <b>** All times in CST</b>
      </div>
      <Table bordered striped hover>
        <thead>
          <tr>
            <th>Staff Name</th>
            <th>Customer Name</th>
            <th>Ticket</th>
            <th>Depart SIS</th>
            <th>Arrive Plant</th>
            <th>Depart Plant</th>
            <th>Arrive SIS</th>
            <th>Total Hours</th>
          </tr>
        </thead>
        <tbody>
          {timeDataWithSummaries.map((timeRecord, i) => {
            if (timeRecord.type === 'item')
              return (
                <tr key={i}>
                  <td>{timeRecord.contact}</td>
                  <td>{timeRecord.customer}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{timeRecord.ticket}</td>
                  <td>
                    {moment(timeRecord.departSIS)
                      .tz('America/Chicago')
                      .format('MM/DD/YYYY h:mm A')}
                  </td>
                  <td>
                    {moment(timeRecord.arrivePlant)
                      .tz('America/Chicago')
                      .format('MM/DD/YYYY h:mm A')}
                  </td>
                  <td>
                    {moment(timeRecord.departPlant)
                      .tz('America/Chicago')
                      .format('MM/DD/YYYY h:mm A')}
                  </td>
                  <td>
                    {moment(timeRecord.arriveSIS)
                      .tz('America/Chicago')
                      .format('MM/DD/YYYY h:mm A')}
                  </td>
                  <td>{timeRecord.totalHours}</td>
                </tr>
              )

            if (timeRecord.type === 'summary')
              return (
                <tr key={i}>
                  <td>
                    <b>{timeRecord.contact}</b>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <b>{timeRecord.totalHours}</b>
                  </td>
                </tr>
              )

            return ''
          })}
        </tbody>
      </Table>
    </div>
  )
}

class TimeReportPage extends React.Component {
  payrollEndDates = getRecentPayrollPeriods()

  constructor(props) {
    super(props)

    this.state = {
      selectedOption: this.payrollEndDates[0],
      validationError: ''
    }
  }

  submitSearch() {
    if (!this.state.selectedOption)
      return this.setState({ ...this.state, validationError: 'Payroll period required' })

    const endDate = this.state.selectedOption.periodEnd
    const startDate = this.state.selectedOption.periodStart
    this.props.actions.getTime(this.props.token, startDate, endDate)
  }

  render() {
    const loaded = !this.props.isFetching

    return (
      <AbstractPage sidebar={{ Sidebar: UserSidebar }}>
        {rif(
          this.state.validationError,
          <div className="alert alert-danger">{this.state.validationError}</div>
        )}

        <form className="time-report-form">
          <ControlLabel>Week Ending</ControlLabel>
          <Select
            placeholder="Select period ending date..."
            required={true}
            searchable={false}
            clearable={false}
            value={this.state.selectedOption}
            onChange={selectedOption => this.setState({ ...this.state, selectedOption })}
            options={this.payrollEndDates}
            getOptionValue={e => e.periodEnd}
          />
          <Button
            className="pull-right"
            bsStyle="primary"
            type="button"
            onClick={this.submitSearch.bind(this)}
          >
            Search
          </Button>
        </form>

        <div style={{ clear: 'both' }} />
        <br />

        <Loader loaded={loaded}>
          {rif(
            this.props.errorText,
            <div className="alert alert-danger">{this.props.errorText}</div>
          )}
          {loaded && this.props.timeData !== undefined ? renderTable(this.props.timeData) : ''}
        </Loader>
      </AbstractPage>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: state.time.isFetching,
  timeData: state.time.timeData,
  errorText: state.time.error,
  username: state.auth.username,
  token: state.auth.token
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getTime }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeReportPage)
