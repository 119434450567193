import React from 'react'
import { NavItem, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const getNavItemElements = navItems =>
  navItems.map((navItem, i) => (
    <LinkContainer key={i} to={navItem.link}>
      <NavItem eventKey={i}>{navItem.text}</NavItem>
    </LinkContainer>
  ))

export default ({ activeIndex, navItems }) => (
  <Nav className="navbar-default" stacked id="sidebar" activeKey={activeIndex}>
    {getNavItemElements(navItems)}
  </Nav>
)
