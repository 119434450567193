import React from 'react'
import { Table } from 'react-bootstrap'
import classNames from 'classnames'

class ManageUser extends React.Component {
  rowClicked(user) {
    this.props.onSelect(user)
  }

  render() {
    const { users = [], selectedUsers = [], title } = this.props
    const sortedUsers = users.sort((a, b) => {
      if (a.firstName < b.firstName) return -1
      if (a.firstName > b.firstName) return 1
      return 0
    })
    return (
      <Table bordered>
        <thead>
          <tr>
            <th>{title}</th>
            <th>First Name</th>
            <th>Last Name</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user, i) => (
            <tr
              style={{ cursor: 'pointer' }}
              className={classNames({ 'table-active': !!selectedUsers.includes(user.email) })}
              key={i}
              onClick={() => this.rowClicked(user)}
            >
              <td>{user.email}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

export default ManageUser
