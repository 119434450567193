// @flow

import { combineReducers } from '../utils'

const initialState = {
  timeData: undefined,
  isFetching: false,
  error: undefined
}

export default combineReducers(initialState, {
  RECEIVE_TIME_DATA: (state, payload) => ({
    ...state,
    timeData: [...payload.timeData],
    isFetching: false
  }),
  FETCH_TIME_DATA_REQUEST: state => ({
    ...state,
    isFetching: true,
    error: undefined
  }),
  FETCH_TIME_DATA_ERROR: (state, payload) => ({
    ...state,
    isFetching: false,
    error: payload
  })
})
