import React from 'react'
import { connect } from 'react-redux'
import AdminSidebar from '../../containers/Sidebar/AdminSidebar'
import AbstractPage from '../AbstractPage'
import { fetchUsers } from '../../actions'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import Loader from 'react-loader'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './Admin.css'
import './ManageUsers.css'
import { makeAbsoluteUrl, rif } from '../../utils/index'

const downloadExcelFile = async (filename, stream) => {
  const response = new Response(stream)
  const blob = await response.blob()
  const url = window.URL.createObjectURL(blob)

  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

const getUserElements = users => {
  const sortedUsers = users.sort((a, b) => {
    if (a.lastName < b.lastName) return -1
    if (a.lastName > b.lastName) return 1
    return 0
  })
  return (
    <table className="table table-bordered user-selector-2">
      <thead className="thead-default">
        <tr>
          <th>Email</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Group</th>
          <th>IsAdmin</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((e, i) => (
          <tr key={i} name={e.email}>
            <td>
              {e.email} <Link to={`/admin/manageUsers/${encodeURIComponent(e.email)}`}>Manage</Link>
            </td>
            <td>{e.lastName}</td>
            <td>{e.firstName}</td>
            <td>{e.group}</td>
            <td>{(!!e.isAdmin).toString()}</td>
            <td>{moment(e.createdAt).format('MM/DD/YYYY h:mm A')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

class ManageUsers extends React.Component {
  constructor(props) {
    super(props)

    this.downloadPermissions = this.downloadPermissions.bind(this)
    this.uploadPermissions = this.uploadPermissions.bind(this)
  }

  componentWillMount() {
    this.props.actions.fetchUsers(this.props.auth.token)
  }

  uploadPermissions() {
    const files = this.uploadFileInput.files
    if (files.length > 0) {
      if (files[0].size > 500000) {
        alert('File cannot be larger than 500kb')
        return
      }

      const acceptedFileTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ]
      if (acceptedFileTypes.indexOf(files[0].type) < 0) {
        alert('File must be named *.xls or *.xlsx')
        return
      }

      const formData = new FormData()
      formData.append('file', files[0], files[0].name)

      this.uploadFileInput.value = null
      fetch(makeAbsoluteUrl('/api/protected/uploadPermissions'), {
        method: 'POST',
        headers: { Authorization: `Bearer ${this.props.auth.token}` },
        body: formData
      })
        .then(response => response.json())
        .then(response => {
          if (response.result) {
            alert(
              'Uploaded permission successfully. Please reload the page and spot check permissions.'
            )
          } else {
            alert('Permissions upload failed. ' + response.error)
          }
        })
    }
  }

  downloadPermissions() {
    fetch(makeAbsoluteUrl('/api/protected/downloadPermissions'), {
      headers: { Authorization: `Bearer ${this.props.auth.token}` }
    }).then(response => {
      return downloadExcelFile(
        `permissions-${moment().format('MM-DD-YYYY-HH-mm-ss')}.xlsx`,
        response.body
      )
    })
  }

  render() {
    const loaded = !this.props.isFetching && this.props.users !== undefined
    const loggedInUser = (this.props.users || []).find(e => e.email === this.props.auth.username)
    console.log('user', loggedInUser)

    return (
      <AbstractPage sidebar={{ Sidebar: AdminSidebar, activeIndex: 0 }}>
        <Loader loaded={loaded}>
          {!loaded ? (
            ''
          ) : (
              <div>
                {rif(
                  this.props.auth.isAdmin,
                  <>
                    <Button onClick={this.downloadPermissions}>Download Permissions</Button>
                    <Button
                      style={{ marginLeft: '20px' }}
                      onClick={() => this.uploadFileInput.click()}
                    >
                      Upload Permissions
                  </Button>
                  </>
                )}
                <input
                  ref={input => (this.uploadFileInput = input)}
                  style={{ opacity: 0 }}
                  type="file"
                  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, application/vnd.ms-excel"
                  onChange={this.uploadPermissions}
                />
                {getUserElements(
                  this.props.users.filter(e => {
                    if (this.props.auth.isAdmin) return true
                    if (this.props.auth.isGroupAdmin && (!e.group || e.group === loggedInUser.group))
                      return true
                    return false
                  })
                )}
              </div>
            )}
        </Loader>
      </AbstractPage>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: state.users.isFetching,
  users: state.users.users,
  auth: {
    token: state.auth.token,
    isAdmin: state.auth.isAdmin,
    isGroupAdmin: state.auth.isGroupAdmin,
    username: state.auth.username
  }
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchUsers }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsers)
