import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { changeMyPassword } from '../actions'
import { Button } from 'react-bootstrap'
import { FieldGroup, rif } from '../utils'
import AbstractPage from './AbstractPage'

export class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      showMessage: false
    }

    this.handleChangePassword = this.handleChangePassword.bind(this)
  }

  componentWillMount() {
    this.setState({ ...this.state, showMessage: false })
  }

  handleChangePassword(e) {
    e.preventDefault()
    this.props.actions.changeMyPassword(this.props.token, this.state.password)
    this.setState({ ...this.state, showMessage: true })
  }

  render() {
    return (
      <AbstractPage>
        <div className="col-xs-12 col-md-6 col-md-offset-3">
          {rif(
            this.props.statusText && this.state.showMessage,
            <div className="alert alert-info">{this.props.statusText}</div>
          )}
          <h2>Change Password</h2>
          <form>
            <FieldGroup
              type="text"
              label="Password"
              placeholder="Enter password"
              onChange={event => this.setState({ ...this.state, password: event.target.value })}
            />
            <Button
              className="pull-right"
              bsStyle="primary"
              type="submit"
              onClick={this.handleChangePassword}
            >
              Submit
            </Button>
          </form>
        </div>
      </AbstractPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  isChangingPass: state.users.isChangingPass,
  statusText: state.users.changePassText,
  token: state.auth.token
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ changeMyPassword }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordPage)
