import React from 'react'
import { connect } from 'react-redux'
import AdminSidebar from '../../containers/Sidebar/AdminSidebar'
import AbstractPage from '../AbstractPage'

import './Admin.css'

class AdminPage extends React.Component {
  render() {
    return (
      <AbstractPage sidebar={{ Sidebar: AdminSidebar }}>
        <p>Hello, {this.props.auth.username}. You're an admin!</p>
      </AbstractPage>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    username: state.auth.username
  }
})

export default connect(mapStateToProps)(AdminPage)
