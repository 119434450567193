import { combineReducers } from '../utils'
import decode from 'jwt-decode'

const initialState = {
  token: null,
  username: null,
  isAdmin: false,
  isGroupAdmin: false,
  group: undefined,
  isAuthenticated: false,
  isAuthenticating: false,
  loginText: null,
  signupText: null
}

export default combineReducers(initialState, {
  LOGIN_USER_REQUEST: (state, payload) => ({
    ...state,
    isAuthenticating: true,
    loginText: null,
    signupText: null
  }),
  LOGIN_USER_SUCCESS: (state, payload) => {
    const decoded = decode(payload.token)
    const isAdmin = decoded.scope && decoded.scope.includes('admin')
    const isGroupAdmin = decoded.scope && decoded.scope.includes('groupAdmin')
    console.log(decoded)

    return {
      ...state,
      isAuthenticating: false,
      isAuthenticated: true,
      isAdmin: isAdmin,
      isGroupAdmin,
      token: payload.token,
      username: decoded.username,
      loginText: 'You have been successfully logged in.'
    }
  },
  LOGIN_USER_FAILURE: (state, payload) => ({
    ...state,
    isAuthenticating: false,
    isAuthenticated: false,
    isAdmin: false,
    isGroupAdmin: false,
    token: null,
    username: null,
    group: undefined,
    loginText: `Authentication Error: ${payload.status} ${payload.statusText}`
  }),
  LOGOUT_USER: (state, payload) => ({
    ...state,
    isAuthenticated: false,
    token: null,
    username: null,
    group: undefined,
    loginText: 'You have been successfully logged out.'
  }),
  SIGNUP_USER_SUCCESS: state => ({
    ...state,
    loginText: 'Successfully signed up. Please login'
  }),
  SIGNUP_FAILURE: (state, payload) => ({
    ...state,
    signupText: `Signup Error: ${payload.status} ${payload.statusText}`
  })
})
