import React from 'react'
import ReactDOM from 'react-dom'
import createHistory from 'history/createBrowserHistory'
import { unregister } from './registerServiceWorker'
import { loginUserSuccess, logoutAndRedirect } from './actions'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import { makeAbsoluteUrl } from './utils'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'

const history = createHistory()
const store = configureStore(history)

const renderReact = () => {
  ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'))
  unregister()
}

const token = localStorage.getItem('token')
if (token !== null) {
  fetch(makeAbsoluteUrl('/api/refreshToken'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token })
  })
    .then(response => response.json())
    .then(body => {
      if (body.result === true) {
        store.dispatch(loginUserSuccess(body.token))
      }
    })
    .catch(() => store.dispatch(logoutAndRedirect()))
    .then(() => renderReact()) // don't render react until we are completely logged in
} else {
  renderReact()
}
